import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { Subscription } from 'rxjs';
import { AppServiceService } from '../../_services/app-service.service';
import { AuthService } from '../../_services/authservice';
import { LayoutService } from '../../_services/layout.service';
import { SideBarComponent } from '../../_shared/components/side-bar/side-bar.component';
import { TopBarComponent } from '../../_shared/components/top-bar/top-bar.component';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { BreadcrumbComponent } from '../../_shared/components/breadcrumb/breadcrumb.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [RouterOutlet,FormsModule, TopBarComponent,NzLayoutModule,SideBarComponent,CommonModule,NgxLoadingModule,BreadcrumbComponent,TranslateModule],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})

export class DashboardComponent implements OnInit {
  subscription: Subscription = new Subscription();

 
  loading=false;
  useSpinner=true;
  selectedReferenceId:any;

 public config = {
   animationType: ngxLoadingAnimationTypes.cubeGrid,
   backdropBackgroundColour: "rgba(0,0,0,0.5)",
   primaryColour: '#ffffff',
   secondaryColour: '#ccc',
   tertiaryColour: '#ffffff',
   backdropBorderRadius: '4px',
   
 };
  
  constructor( public authService: AuthService,private layoutService:LayoutService,
   public appService:AppServiceService) {


  }
  ngOnInit(): void {

    // this.langService.SelectedLang.subscribe(lang=>{
    //   this.SelectedLang=lang;
    // })
    this.subscription.add(
      this.layoutService.useSpinner.subscribe(
        (useSpinner) => (
          setTimeout(() => {
            this.useSpinner = useSpinner
          }, 1))
      )
    );

    this.layoutService.Spinner.subscribe(loading=>{
      this.loading=loading;
    })
    
    // this.layoutService.Spinner.subscribe(s=>{
    //   if(s)
    //     this.spinnerService.show("AZSpinner")
    //   else
    //   this.spinnerService.hide("AZSpinner")
    // })
    // this.subscription.add(
    //   this.layoutService.getTabTitle.subscribe(
    //     (TabTitle) => (
    //       setTimeout(() => {
    //         this.showSpinner = TabTitle.loading
    //       }, 1))
    //   )
    // );  
  }

  selectReference(referance:any)
  {}
}
